import { BrowserRouter, Routes, Route } from "react-router-dom";
import PdfViewer from "./Test/PdfViewer";
const App = () => {
  return (
    <>
      <title>Bakım</title>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<>Hata!</>}></Route>
          <Route path="/" element={<></>}>
            <Route index element={<>Bakım yapılmaktadır</>} />
          </Route>
          <Route path="3+d47qv4ncXlaXACk8SIL1YzEu01fCs5iMdC98wpABo=" element={<PdfViewer />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
