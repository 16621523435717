import React, { useEffect } from 'react';
import { pdfBase64 } from './base64';

interface IPdfViewerProps { src?: any };

const Base64PDFViewer = (props: IPdfViewerProps) => {
    useEffect(() => {
        const showBase64PDF = async (base64Data: any) => {
            var blob = null;
            await fetch('data:application/pdf;base64,' + pdfBase64)
                .then(r => r.blob())
                .then(res => {
                    blob = URL.createObjectURL(res);
                }).catch(e => e);

            var iframe = document.createElement('iframe');
            iframe.style.width = '100%';
            iframe.style.height = '1080px';
            iframe.src = "3+d47qv4ncXlaXACk8SIL1YzEu01fCs5iMdC98wpABo=.pdf";

            document.body.appendChild(iframe);
        }

        showBase64PDF(props.src);

        return () => {
            var iframe = document.querySelector('iframe');
            if (iframe) {
                document.body.removeChild(iframe);
            }
        };
    }, [props.src]);

    return (
        <>
            {!props.src && <div>PDF yükleniyor...</div>}
        </>
    );
};

const PdfViewer = (props: IPdfViewerProps): JSX.Element => {
    return (
        <>
            {Base64PDFViewer({ src: pdfBase64 })}
        </>
    );
};

export default PdfViewer;